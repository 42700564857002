<template>
    <v-list>
        <v-list-item v-for="(client,i) in clients" :key="i" link @click="onConfirmHour(client.id)">
            <v-list-item-content>
                <v-list-item-title v-text="client.name"/>
            </v-list-item-content>

            <v-list-item-avatar>
                <v-avatar size="30" :color="isClientConfirm(client) ? 'secondary' : '#b3b3b3'">
                    <v-icon color="white" size="20" v-text="isClientConfirm(client) ? '$check' : ''"/>
                </v-avatar>
            </v-list-item-avatar>
        </v-list-item>
    </v-list>
</template>

<script>

export default {
    name: "ClientOperations",
    props: {
        clients: {
            type: Array,
            default: () => [],
        }
    },
    methods: {
        onConfirmHour(id) {
            const vm = this
            vm.goTo({name: `${vm.accountType}.clients.details`, params: {id: id}})
        },
        isClientConfirm(client) {
            return client.confirmed_hours?.[0].confirmed || false
        }
    }
}
</script>

<style scoped>

</style>