import { render, staticRenderFns } from "./ClientOperations.vue?vue&type=template&id=077a2096&scoped=true&"
import script from "./ClientOperations.vue?vue&type=script&lang=js&"
export * from "./ClientOperations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "077a2096",
  null
  
)

export default component.exports