<template>
    <v-row :style="style" align="center">
        <v-spacer/>
        <v-progress-linear
            v-if="line"
            indeterminate
            :color="color"
            :size="size"
            :width="width"
        />
        <v-progress-circular
            v-else
            indeterminate
            :color="color"
            :size="size"
            :width="width"
        />
        <v-spacer/>
    </v-row>
</template>

<script>
export default {
    name: "LoadingSpinner",
    props: {
        height: {
            type: String,
            default: '200px',
        },
        color: {
            type: String,
            default: 'primary',
        },
        size: {
            type: [Number, String],
            default: 50,
        },
        width: {
            type: [Number, String],
            default: 4,
        },
        line: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        style() {
            return `height: ${this.height}`
        }
    }
}
</script>

<style scoped>

</style>